import { titleCase } from "title-case";
import { IStaff } from "../models/IStaff";
import { IStaffMember, phoneNumberUtils } from "@fdot/arculus-staff-service";

export const staffMap = (staff: IStaffMember): IStaff => {
    return {
        srsId: staff.id,
        emailAddress: staff.emailAddress,
        azureAdOid: staff.azureAdOid,
        firstName: staff.firstName,
        lastName: staff.lastName,
        racfId: staff.racfId,
        phoneNumber: phoneNumberUtils.formatPhoneNumber(staff.phone, staff.phoneExtension),
        positionWorkTitle: staff.positionWorkTitle !== undefined && staff.positionWorkTitle !== null
            ? titleCase(staff.positionWorkTitle)
            : '',
        external: false
    }
};

export const getUserInitials = (user: IStaff | null | undefined): string => {
    if (user === null || user === undefined) {
        return "?"
    }
    let initials = '';
    if (user.firstName && user.firstName.length > 0) {
        initials += user.firstName[0];
    }
    if (user.lastName && user.lastName.length > 0) {
        initials += user.lastName[0];
    }
    return initials;
};

export const getFirstNameLastName = (user: IStaff | null | undefined): string => {
    if (user === undefined || user === null) {
        return '';
    }
    return `${user.firstName} ${user.lastName}`;
};