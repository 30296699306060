import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IBlockedStaff } from "../../../../../shared/models/IBlockedStaff";

// Define a service using a base URL and expected endpoints
export const blockedStaffApi = createApi({
  reducerPath: "blockedStaffApi",
  baseQuery: fetchBaseQuery({
    baseUrl: fdot.process.env.BACKEND_SERVER_HOST,
    credentials: "include",
  }),
  tagTypes: ["BlockedStaff"],
  endpoints: (builder) => ({
    getBlockedStaff: builder.query<IBlockedStaff[], void>({
      query: () => `api/blockedStaff`,
      providesTags: ["BlockedStaff"],
    }),
    addBlockedStaff: builder.mutation<IBlockedStaff, IBlockedStaff>({
      query: (blockedStaff) => ({
        url: `api/blockedStaff`,
        method: "POST",
        body: blockedStaff,
      }),
      invalidatesTags: ["BlockedStaff"],
    }),
    removeBlockedStaff: builder.mutation<IBlockedStaff, IBlockedStaff>({
      query: (blockedStaff) => ({
        url: `api/blockedStaff/${blockedStaff._id}`,
        method: "DELETE",
        body: blockedStaff,
      }),
      invalidatesTags: ["BlockedStaff"],
    }),
  }),
});

export const {
  useGetBlockedStaffQuery,
  useAddBlockedStaffMutation,
  useRemoveBlockedStaffMutation,
  useLazyGetBlockedStaffQuery,
} = blockedStaffApi;
