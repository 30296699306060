import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import logger from "redux-logger";
import rootReducer from "./RootReducer";
// import { setupListeners } from '@reduxjs/toolkit/query'
// import { useDispatch } from 'react-redux';
import thunkMiddleware from "redux-thunk";
// import { searchApiSlice } from './slices/apis/search';
import { blockedStaffApi } from "./slices/apis/BlockedStaffApi";

// const middleware = [...getDefaultMiddleware(), logger];
const store = configureStore({
  reducer: rootReducer,
  devTools: fdot.process.env.APP_ENVIRONMENT === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(thunkMiddleware)
      .concat(logger)
      .concat(blockedStaffApi.middleware),
});
// middleware : (getDefaultMiddleware) => getDefaultMiddleware().concat(searchApiSlice.middleware).concat(logger)

if (fdot.process.env.APP_ENVIRONMENT === "development" && (module as any).hot) {
  (module as any).hot.accept("./RootReducer", () => {
    const newRootReducer = require("./RootReducer").default;
    store.replaceReducer(newRootReducer);
  });
}

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;

// setupListeners(store.dispatch);

export default store;
