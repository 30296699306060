// import { State } from "@progress/kendo-data-query";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { IRegion } from "../../../../shared/models/IRegion";
import { IServiceRequestSearchDocument } from "../../../../shared/models/IServiceRequestSearchDocument";
import { IServiceRequest } from "../../../../shared/models/IServiceRequest";
import { logException } from "../../../../shared/services/LogService";

interface ISort {
  field: string;
  dir: "asc" | "desc" | "none";
}
interface ISortSkipState {
  sort: ISort;
  take: number;
  skip: number;
  currentPageIndex: number;
}

export interface ISearchCriteria {
  searchCriteriaState: ISortSkipState;
  searchText: string;
  selectedRegion: IRegion[];
  selectedDepartment: string[];
  selectedBusinessName: string[];
  requestDateBeginDate?: Date;
  requestDateEndDate?: Date;
  countyDistrict: IRegion[];
}

// slice & selectors omitted
type SliceState = {
  criteria: ISearchCriteria;
  loading: boolean;
  serviceRequests: IServiceRequestSearchDocument[];
  totalCount: number;
  serviceRequest: IServiceRequest | null;
};

type ServiceRequestResponse = {
  results: IServiceRequestSearchDocument[];
  totalCount: number;
};

const baseUrl = fdot.process.env.BACKEND_SERVER_HOST;

export const downloadDocumentsUri = (documents: string, serviceId: string) =>
  `${baseUrl}/api/download/${documents}/${serviceId}`;

export const reportPiiEmail = async (email: string) => {
  try {
    const response = await fetch(`${baseUrl}/api/report-pii/${email}`, {
      method: "GET",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    });
    return response.status;
  } catch (error) {
    logException(error);
    return 500;
  }
};

export const fetchServiceRequestById = createAsyncThunk<
  IServiceRequest,
  String
>("service-request-id/fetch", async (id, { rejectWithValue }) => {
  const response = await fetch(`${baseUrl}/api/service-request/${id}`, {
    method: "GET",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });
  const data = await response.json();
  if (response.status < 200 || response.status >= 300) {
    return rejectWithValue(data);
  }

  return data;
});

export const fetchServiceRequests = createAsyncThunk<
  ServiceRequestResponse,
  ISearchCriteria
>("serviceRequests/fetch", async (criteria, { rejectWithValue }) => {
  const response = await fetch(`${baseUrl}/api/serviceRequests`, {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(criteria),
  });

  const data = await response.json();
  if (response.status < 200 || response.status >= 300) {
    return rejectWithValue(data);
  }
  return data;
});

const searchSlice = createSlice({
  name: "search",
  initialState: {
    criteria: {
      searchCriteriaState: {
        sort: { field: "serviceRequestId", dir: "asc" },
        take: 20,
        skip: 0,
        currentPageIndex: 0,
      },
      searchText: "",
      selectedRegion: [],
      selectedDepartment: [],
      selectedBusinessName: [],
      countyDistrict: [],
      requestDateBeginDate: new Date(),
      requestDateEndDate: new Date(),
    },
    loading: false,
    serviceRequests: [],
    totalCount: 0,
    serviceRequest: null,
  } as SliceState,
  reducers: {},
  extraReducers: (builder) => {
    // When our request is pending:
    // - store the 'pending' state as the status for the corresponding pokemon name
    builder.addCase(fetchServiceRequests.pending, (state, action) => {
      state.criteria = action.meta.arg;
      state.loading = true;
      state.serviceRequests = [];
      state.totalCount = 0;
    });
    // When our request is fulfilled:
    // - store the 'fulfilled' state as the status for the corresponding pokemon name
    // - and store the received payload as the data for the corresponding pokemon name
    builder.addCase(fetchServiceRequests.fulfilled, (state, action) => {
      // state.criteria = action.meta.arg;
      state.loading = false;
      state.serviceRequests = action.payload.results;
      state.totalCount = action.payload.totalCount;
    });
    // When our request is rejected:
    // - store the 'rejected' state as the status for the corresponding pokemon name
    builder.addCase(fetchServiceRequests.rejected, (state, action) => {
      // state.criteria = action.meta.arg;
      state.loading = false;
      state.serviceRequests = [];
      state.totalCount = 0;
    });

    // When our request is pending:
    // - store the 'pending' state as the status for the corresponding pokemon name
    builder.addCase(fetchServiceRequestById.pending, (state, action) => {
      state.loading = true;
      state.serviceRequest = null;
    });
    // When our request is fulfilled:
    // - store the 'fulfilled' state as the status for the corresponding pokemon name
    // - and store the received payload as the data for the corresponding pokemon name
    builder.addCase(fetchServiceRequestById.fulfilled, (state, action) => {
      // state.criteria = action.meta.arg;
      state.loading = false;
      state.serviceRequest = action.payload;
    });
    // When our request is rejected:
    // - store the 'rejected' state as the status for the corresponding pokemon name
    builder.addCase(fetchServiceRequestById.rejected, (state, action) => {
      // state.criteria = action.meta.arg;
      state.loading = false;
      state.serviceRequest = null;
    });
  },
});

export default searchSlice.reducer;
