import React, { CSSProperties } from 'react';

const Footer = () => {

    const footerStyles : CSSProperties = {
        backgroundColor:"#EDF1F5"
    }

    return (
        <div className="container-fluid">
            <div className="row justify-content-md-center">
                <nav className="navbar fixed-bottom navbar-light" style={footerStyles}>
                    <div className="col text-center">
                        GovQA Archive Data Solution
                    <br />
                    Report technical problems to the&nbsp;
                    <a href="mailto:FDOT.ServiceDesk@dot.state.fl.us?GovQA Data Archive" title="Use this link to E-Mail concerns, problems, and/or comments">
                            Service Desk
                    </a>
                    &nbsp;at 1-866-955-4357
                    <br />
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Web Policies and Notices (Opens new browser window)"
                            href="https://www.fdot.gov/agencyresources/webpoliciesandnotices.shtm">
                            Web Policies and Notices
                        </a>
                        &nbsp; - &nbsp;
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.fdot.gov/agencyresources/notices/accessibility.shtm"
                            title="Accessibility Statement  (Opens new browser window)">
                            Accessibility Statement
                        </a>
                    </div>
                </nav >
            </div>
        </div >
    );
}

export default Footer;