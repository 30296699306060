import { combineReducers } from "@reduxjs/toolkit";
import configReducer from "./slices/ConfigSlice";
import profileReducer from "./slices/ProfileSlice";
// import { searchApiSlice } from "./slices/apis/search";
import searchReducer from "./slices/searchSlice";
import { blockedStaffApi } from "./slices/apis/BlockedStaffApi";
import { userApi } from "./slices/apis/UserApi";

const rootReducer = combineReducers({
  configReducer,
  profileReducer,
  searchReducer,
  [blockedStaffApi.reducerPath]: blockedStaffApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  // [searchApiSlice.reducerPath]: searchApiSlice.reducer // New Using RTK queries
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
