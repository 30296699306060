import React, { Suspense } from "react";
import Loading from "react-loading";
import { Routes, Route } from "react-router-dom";
import { useGetUserQuery } from "../../redux/slices/apis/UserApi";

const Home = React.lazy(() => import("../Home"));
const NotAuthorized = React.lazy(() => import("../NotAuthorized"));
const NoMatch = React.lazy(() => import("../NoMatch"));
const Staff = React.lazy(() => import("../staff/Staff"));
const LoginFailure = React.lazy(() => import("../LoginFailure"));
const ServiceRequest = React.lazy(
  () => import("../search/SearchRequestDetails")
);

const StaffAuthenticatedRoutes = () => [
  <Route path="/Staff" element={<Staff />} key="Staff" />,
];

const AuthenticatedRoutes: React.FC = () => {
  const { data: user } = useGetUserQuery();

  return (
    <Suspense fallback={<Loading></Loading>}>
      <Routes>
        <Route path="/" element={<Home />} key="index" />
        <Route
          path="/service-request/:id"
          element={<ServiceRequest />}
          key="service-request"
        />
        <Route
          path="/NotAuthorized"
          element={<NotAuthorized />}
          key="NotAuthorized"
        />
        <Route
          path="/LoginFailure"
          element={<LoginFailure />}
          key="LoginFailure"
        />
        {user && !user.external && StaffAuthenticatedRoutes()}
        <Route path="*" element={<NoMatch />} key="NoMatch" />
      </Routes>
    </Suspense>
  );
};

export default AuthenticatedRoutes;
