import React from 'react';
import { useGetUserQuery } from '../redux/slices/apis/UserApi';

const LogOut: React.FC = () => {
  const {data:user} = useGetUserQuery();
    const logoutUrl = user?.azureAdOid && user?.external ? `${fdot.process.env.BACKEND_SERVER_HOST}/logoutB2c` : `${fdot.process.env.BACKEND_SERVER_HOST}/logout`;

    return (
        <a className='nav-link' href={logoutUrl}>Sign Out</a>
    );
};

export default LogOut;